import { createStandaloneToast } from '@chakra-ui/react';

const DEFAULT_TOAST_OPTIONS = {
  position: 'top',
  duration: 6000,
  isClosable: true,
  containerStyle: {
    marginBottom: '0px',
    marginTop: '20px',
  },
};

export const createToast = (title, status, SORT_OPTIONS) => {
  if (title) {
    const { toast } = createStandaloneToast();
    toast({ title, status, ...DEFAULT_TOAST_OPTIONS });
  }
};

export const toastError = (message, options) => {
  createToast(message, 'error');
};

export const toastSuccess = (message, options) => {
  createToast(message, 'success');
};

export const toastInfo = (message, options) => {
  createToast(message, 'info');
};

export const listAddOrRemove = (values, val) => {
  return values.includes(val) ? values.filter(v => v !== val) : [...values, val];
};

export const insertIf = (item, condition) => {
  return condition ? [item] : [];
};

export const buildQueryString = data => {
  return new URLSearchParams(data).toString();
};

export const range = n => Array.from(Array(n).keys());

