import React from 'react';

const ErrorScreen = props => {
  return (
    <>
      <h1>There was an error.</h1>
    </>
  );
};
export default ErrorScreen;
