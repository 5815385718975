
export const arrayToTrue = (array, keyField) => {
  if (!array) return {};
  return array.reduce((obj, item) => {
    obj[item[keyField]] = true;
    return obj;
  }, {});
};

export const arrayToObject = (array, keyField) => {
  if (!array) return {};
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
};

export const arrayToObjectValues = (array, keyField, valueField) => {
  if (!array) return {};
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item[valueField];
    return obj;
  }, {});
};

export const arrayToObjectList = (array, keyField) => {
  return array.reduce((obj, item) => {
    (obj[item[keyField]] = obj[item[keyField]] ? obj[item[keyField]] : []).push(item);
    return obj;
  }, {});
};

export const arrayToObjectValuesList = (array, keyField, valueField) => {
  return array.reduce((obj, item) => {
    (obj[item[keyField]] = obj[item[keyField]] ? obj[item[keyField]] : []).push(item[valueField]);
    return obj;
  }, {});
};

export const arrayAddOrReplace = (items, newItem, keyField) => {
  const newItems = [...items];
  const index = items.findIndex(item => item[keyField] === newItem[keyField]);

  if (index === -1) {
    newItems.push(newItem);
  } else {
    newItems[index] = newItem;
  }
  return newItems;
};

export const arrayToCount = array => {
  return array.reduce((obj, item) => {
    obj[item] = (obj[item] || 0) + 1;
    return obj;
  }, {});
};

export const objMap = (obj, func) => {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, func(v)]));
};

export const placeItemFirst = (array, itemId) => {
  if (itemId) {
    const item = array.find(item => item.id === itemId);
    if (item) {
      return [item, ...array.filter(item => item.id !== itemId)];
    }
  }
  return array;
};