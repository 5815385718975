import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  ratingForm: {},
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setRateEventFormField: (state, { payload }) => {
      state.ratingForm[payload.eventId] = {
        ...state.ratingForm[payload.eventId],
        [payload.field]: payload.value,
      };
    },
    setRateEventUserFormField: (state, { payload }) => {
      const profiles = state.ratingForm[payload.eventId].profiles || {};
      state.ratingForm[payload.eventId] = {
        ...state.ratingForm[payload.eventId],
        profiles: {
          ...profiles,
          [payload.profileId]: {
            ...profiles[payload.profileId],
            [payload.field]: payload.value,
          },
        },
      };
    },
    setInitialData: (state, action) => {
      const { formKey, initialData } = action.payload;
      setInitialFormState(state, formKey);
      state[formKey].initialData = initialData;
    },
    setField: (state, action) => {
      const { formKey, field, value } = action.payload;
      setInitialFormState(state, formKey);
      state[formKey].fieldsChanged[field] = value;
    },
    setFields: (state, action) => {
      const { formKey, updatedFields } = action.payload;
      setInitialFormState(state, formKey);
      state[formKey].fieldsChanged = { ...state[formKey].fieldsChanged, ...updatedFields };
    },
    resetForm: (state, action) => {
      const { formKey } = action.payload;
      state[formKey] = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRateEventFormField, setRateEventUserFormField, setInitialData, setField, setFields, resetForm } =
  formSlice.actions;

export default formSlice.reducer;

// export default (state = initialState, action) => {
//   const payload = action.payload;
//   const base_action = action.type.replace(FULFILLED, '');
//   switch (base_action) {
//     case actions.SET_RATE_EVENT_FORM_FIELD:
//       return {
//         ...state,
//         ratingForm: {
//           ...state.ratingForm,
//           [payload.eventId]: {
//             ...state.ratingForm[payload.eventId],
//             [payload.field]: payload.value,
//           },
//         },
//       };
//     case actions.SET_RATE_EVENT_USER_FORM_FIELD: {
//       const profiles = state.ratingForm[payload.eventId].profiles || {};
//       return {
//         ...state,
//         ratingForm: {
//           ...state.ratingForm,
//           [payload.eventId]: {
//             ...state.ratingForm[payload.eventId],
//             profiles: {
//               ...profiles,
//               [payload.profileId]: {
//                 ...profiles[payload.profileId],
//                 [payload.field]: payload.value,
//               },
//             },
//           },
//         },
//       };
//     }
//     default:
//       return state;
//   }
// };
